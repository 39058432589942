.ftu-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--spacing-xl, 16px);
    padding: var(--spacing-xl, 16px) var(--spacing-xxl, 32px);
    align-items: flex-start;
    background: linear-gradient(15deg, #35555B 10.12%, #0A0A0A 51.24%);
}

.ftu-step-container {
    justify-content: center;
    gap: var(--spacing-xl, 16px);
    align-items: flex-start;
    padding: var(--spacing-5xl);
}

.ftu-content-container {
    display: flex;
    padding: var(--spacing-4xl, 40px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-none, 0px);
    flex: 1 0 0;
    align-self: stretch;
        
    /* Shadows/shadow-md */
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}

.ftu-form-card {
    justify-content: center;
    flex: 1 0 0;
    align-self: stretch;
    background: var(--bg-primary, #1A1A1A);

    padding: var(--spacing-8xl) var(--spacing-7xl) var(--spacing-7xl) var(--spacing-7xl);
    gap: var(--spacing-4xl, 32px);
    border-radius: var(--radius-xl);
}

.ftu-form-card.form {
    gap: var(--spacing-3xl, 24px);
}

.ftu-form-row {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-3xl, 24px);
    align-items: center;
    align-self: stretch;
}

.ftu-title {
    font-family: var(--font-family-primary, 'Lato');
    font-size: 60px;
    font-weight: 700;
    letter-spacing: -0.02em;
    text-align: center;
    color: var(--text-primary, #F7F7F7);
}

.ftu-subtitle {
    font-family: var(--font-family-secondary, 'Rubik');
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    color: var(--text-secondary, #ECECED);
}

.ftu-step {
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-xl, 16px);
    align-self: stretch;
}

.ftu-step-icon {
    display: flex;
    padding: var(--spacing-lg, 12px);
    align-items: center;
    gap: var(--spacing-md, 8px);
    
    border-radius: var(--radius-md, 8px);
    border: 1px solid var(--text-placeholder_subtle, #61646c);
    
    /* Shadows/shadow-xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.ftu-step-icon.active {
    color: white;
    border: 1px solid var(--Component-colors-Utility-Orange-utility-orange-4, #F9B26F);
}

.ftu-step-line {
    width: 1px;
    height: 46px;
    background: var(--fg-quaternary, #61646C);
}

.ftu-step-line.active {
    background: var(--fg-brand-secondary, #F9B26F);
}
    
  .ftu-step-icon-line {
    display: flex;
    padding: var(--spacing-none, 0px);
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-none, 0px);
  }

  .ftu-step-text {
    display: flex;
    padding: var(--spacing-xxs, 2px) var(--spacing-none, 0px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--spacing-none, 0px);
    flex: 1 0 0;
  }

.ftu-step-title {
    color: var(--text-placeholder-subtle, #61646c);
    font-family: var(--font-family-primary, 'Lato');
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
}

.ftu-step-title.active {
    color: var(--text-primary, #F7F7F7);
}

.ftu-step-subtitle {
    color: var(--text-placeholder-subtle, #61646c);
    font-family: var(--font-family-secondary, 'Rubik');
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.ftu-step-subtitle.active {
    color: var(--text-secondary, #ECECED);
}