.outer-box-top {
    margin: 2px 2px;
    max-width: 100%;
    display: grid;
    grid-template-columns: 25% 75%;
    vertical-align: top;
    overflow: auto;
}

.outer-box-top-20-80 {
    margin: 2px 2px;
    max-width: 100%;
    display: grid;
    grid-template-columns: 20% 80%;
    vertical-align: top;
    overflow: auto;
}

.outer-box-top-three {
    margin: 2px 2px;
    max-width: 100%;
    display: grid;
    grid-template-columns: 20% 50% 25%;
    vertical-align: top;
    overflow: auto;
}

.work_profile {
    font-family: var(--font-family);
    max-width: 100%;
    margin: auto auto;
    padding: 20px;
    border-radius: 5px;
    background-color: var(--back-ground-color);
    display: grid;
    grid-template-columns: max-content auto;
    grid-row-gap: 5px;
    align-items: center;
}

.file_upload {
    font-family: var(--font-family);
    max-width: 95%;
    padding: 10px;
    border-radius: 5px;
    background-color: var(--back-ground-color);
    color: var(--text-primary);
    display: grid;
    grid-template-rows: auto auto;
    grid-row-gap: 5px;
    align-items: center;
    border: 1px solid gray;
}

.left-pane {
    background-color: var(--back-ground-color);
    flex: 1;
    max-width: 100%;
    overflow: auto;
    vertical-align: top;
    border-right: 1px solid var(--bg-brand-secondary);
}

.right-pane-profile {
    flex: 2;
    max-width: 100%;
    vertical-align: center;
    box-sizing: border-box;
    overflow: auto;
    margin-left: 10px;
}

.right-pane-jobs {
    flex: 2;
    width: 80%;
    max-width: 100%;
    vertical-align: center;
    box-sizing: border-box;
    overflow: auto;
    margin-left: 10px;
}

.right-pane-teams {
    flex: 2;
    height: 80vh;
    min-height: auto;
    display: flex;
    flex-direction: column;
    text-align: left;
    /* Aligns text to the left */
    box-sizing: border-box;
    overflow: auto;
    margin-left: 10px;
}

.right-pane-teams-narrow {
    flex: 2;
    height: 80vh;
    max-width: 60%;
    min-height: auto;
    display: flex;
    flex-direction: column;
    text-align: left;
    /* Aligns text to the left */
    box-sizing: border-box;
    overflow: auto;
    margin-left: 10px;
}

.right-pane-teams-wide {
    flex: 2;
    max-width: 100%;
    height: 80vh;
    min-height: auto;
    display: flex;
    flex-direction: column;
    text-align: left;
    /* Aligns text to the left */
    box-sizing: border-box;
    overflow: auto;
    margin-left: 10px;
}



.work_profile label {
    padding-right: 10px;
    text-align: right;
}

.work_profile h2 {
    grid-column: span 2;
    padding-bottom: 0;
    margin-bottom: 2px;
    margin-top: 0;
}

.col_buttons {
    font-family: var(--font-family);
    max-width: 80%;
    margin: auto auto auto auto;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    align-items: center;
}

.col_buttons button {
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
    max-width: 10%;
}

.skill_status {
    color: var(--text-primary);
    display: flex;
    padding: 5px;
    width: 40%;
    flex-direction: column;
    text-align: left;
}

.email_template {
    background-color: white;
    text-align: left;
    color: black;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

.select_cohort {
    width: 80%;
}