.login-container {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-5xl, 40px);

    background: var(--Gradient-Dark);
}

.login-box {
    align-self: normal;
    max-height: fit-content;
    height: fit-content;
    margin: 50px auto;
    padding: 20px;
    min-width: 30%;
    border-radius: var(--radius-sm);
    /*border: 1px solid var(--border-brand);*/
}

.login-box input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: var(--radius-sm);
    border: 1px solid var(--border-brand);
    background-color: transparent;
    color: var(--text-primary);
}

.login-title {
    font-family: var(--font-family-primary, 'Lato');
    font-size: 40px;
    font-weight: 700;
    color: var(--text-primary);
    text-align: left;
    letter-spacing: -2%;
    line-height: 60px;
    margin: var(--spacing-md, 8px) 0;
}

.login-subtitle {
    font-family: var(--font-family-secondary, 'Rubik');
    font-size: 20px;
    font-weight: 400;
    color: var(--text-tertiary);
    text-align: center;
    padding: var(--spacing-none) var(--spacing-lg, 12px);
}

.login-footer {
    font-family: var(--font-family-secondary, 'Rubik');
    font-size: 16px;
    font-weight: 400;
    color: var(--text-tertiary);
    text-align: center;
    padding: var(--spacing-none) var(--spacing-lg, 12px);
}

.login-form-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg, 12px);
    padding: var(--spacing-lg, 12px);
    align-items: center;
}

.login-logo-container {
    display: flex;
    justify-content: center;
    padding: var(--spacing-lg, 12px);
}

.login-otp-info {
    display: flex;
    justify-content: flex-start;
    font-family: var(--font-family-secondary, 'Rubik');
    font-size: 16px;
    font-weight: 300;
    color: var(--text-tertiary);
    text-align: center;
    padding: var(--spacing-md, 8px);
    gap: var(--spacing-2xl);
}

.login-otp-info a {
    color: var(--text-primary);
    text-decoration: none;
}

.login-otp-info a:disabled {
    color: var(--text-tertiary);
    text-decoration: none;
}

.login-image {
    width: 30%;
    height: auto;
    max-height: 40vh;
    max-width: 50%;
    object-fit: contain;
    margin: 0 auto;
    border-radius: 20px!important;
}