table {
    width: 100%;
    border-collapse: collapse;
    color: var(--text-primary);
    table-layout: fixed;
    overflow: auto;
}

.sticky-column {
    position: sticky;
    left: 0;
    border-right: 1px solid white;
    z-index: 1;
}


.sticky-row-1 {
    position: sticky;
    top: 0;
    z-index: 2;
    height: 70px;
}

.sticky-row-2 {
    position: sticky;
    top: 70px;
    z-index: 3;
}

.table-container-search td {
    padding: 8px;
    text-align: center;
    overflow: hidden;
    /* Ensure text does not overflow */
    text-overflow: ellipsis;
    /* Add ellipsis for overflowing text */
    white-space: nowrap;
    /* Prevent text wrapping */
}

.table-container-searchtbody tr {
    cursor: pointer;
}

.table-container-search th {
    padding: 8px;
    text-align: center;
}

.table-container-search thead {
    background-color: #333;
}


.table-container-search tbody tr:nth-child(even) {
    background-color: #444;
}

.table-container-search tbody tr:nth-child(odd) {
    background-color: #222;
}

.extra_line_header tbody tr:nth-child(1) {
    font-weight: bold;
    border-bottom: 1px solid white;
}

.table-container-search th:first-child,
.table-container-search td:first-child {
    border-left: none;
    width: 200px;
}

.table-container-search th:not(:first-child),
.table-container-search td:not(:first-child) {
    width: 100px;
}

.table-container-search table {
    border: 1px solid white;
}

.badges table {
    width: 100%;
    border-collapse: collapse;
    color: white;
    table-layout: fixed;
}

.outer-box-search {
    margin: 20px 20px;
    max-width: 100%;
    height: 80vh;
    display: grid;
    grid-template-rows: 80% 20%;
    overflow: auto;
    gap: 20px;
}

.search-box {
    max-width: 100%;
    max-height: 100%;
    display: grid;
    padding: 10px;
    gap: 10px;
    grid-template-columns: 15% 85%;
}

.table-container-search {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: auto;
}

.search-summary {
    max-width: 100%;
    max-height: 100%;
    display: grid;
    gap: 10px;
    padding: 10px;
    grid-template-columns: 20% 80%;
}

.search-summary-only-table {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    gap: 10px;
    padding: 10px;
}

.search-results {
    max-width: 100%;
    max-height: 85vh;
    vertical-align: top;
    box-sizing: border-box;
    overflow: auto;
    display: grid;
    grid-template-rows: 8% 85% 7%;
}

.candidate-header {
    display: grid;
    grid-template-columns: 60% 15%;
    gap: 10px;
}

.four_small_buttons {
    font-family: var(--font-family);
    max-width: 80%;
    margin: auto auto auto auto;
    border-radius: 5px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.four_small_buttons button {
    padding: 5px 5px 5px 5px;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    min-width: 30px;
}

.four_small_buttons button.selected {
    background-color: var(--bg-brand-solid_hover);
}

.skill-search-row {
    border-radius: var(--radius-sm);
    border: 1px solid var(--border-brand);
    padding: 10px;
    margin-bottom: 10px;
    max-width: 100%;

}

.job-candidate-row {
    border-bottom: 1px solid var(--border-primary);
    padding: 10px;
    margin-bottom: 10px;
    max-width: 100%;
}

.skills-container {
    display: grid;
    gap: 10px;
}

.skills-container.two-columns {
    grid-template-columns: repeat(2, 1fr);
}

.skill_container {
    display: grid;
    gap: 5px;
    grid-template-columns: 20% 30%;
    margin-top: 5px;
    cursor: pointer;
}

.skill_summary {
    font-family: var(--font-family);
    max-width: 100%;
    border-radius: 5px;
    background-color: var(--back-ground-color);
    display: grid;
    grid-template-columns: max-content auto auto;
    grid-row-gap: 5px;
    align-items: center;
}

.job-candidates-list {
    max-width: 100%;
    overflow-y: auto;
    margin-top: 10px;
}

.skill-search-results-container {
    max-width: 100%;
    max-height: 65vh;
    overflow-y: none;
    margin-top: 10px;
}

.skill-search-results-container-scrollable {
    max-width: 100%;
    max-height: 100%;
    overflow-y: auto;
    max-height: 65vh;
    margin-top: 0px;
}

.no_margin_h2 {
    margin: 0;
}

.no_margin_h2_clickable {
    margin: 0;
    cursor: pointer;
}

.no_margin_h2_clickable:hover {
    color: var(--text-brand-secondary);
}

.big_label {
    font-size: 1.2em;
    font-weight: bold;
}

.job-candidate-filter {
    display: grid;
    grid-template-columns: 30% 30% 10% 20% 10%;
    gap: 10px;
}

.job-saved-search {
    display: flex;
    max-width: 70%;
}

.bottom_buttons {
    align-self: center;
    width: fit-content;
    height: 70%;
}


.table-with-border-oss {
    font: var(--font-family);
    width: 100%;
    margin: 3px 1px;
}

.table-with-border-oss thead {
    background-color: #333;
}

.table-with-border-oss th,
.table-with-border-oss td {
    padding: 8px;
    width: 200px;
    max-width: 20%;
    text-align: center;
    overflow-wrap: break-word;
}

.wide_col {
    width: 250px !important;
}

.clickable_col {
    cursor: pointer;
    color: var(--text-brand-secondary);
}

.clickable_col:hover {
    color: white;
    font-size: 1.05em;
}

.non_clickable_col {
    cursor: default;
}

.table-with-border-oss th:first-child,
.table-with-border-oss td:first-child {
    border-left: none;
    width: 250px;
}

.table-with-border-oss th:not(:first-child),
.table-with-border-oss td:not(:first-child) {
    width: 100px;
}

.table-with-border-oss tbody tr:nth-child(even) {
    background-color: #444;
}

.table-with-border-oss tbody tr:nth-child(odd) {
    background-color: #222;
}