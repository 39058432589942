.input-field {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.tags-container {
    display: flex;
    flex-wrap: wrap;
    border-radius: 4px;
    border: 1px solid var(--border-brand);
    gap: 5px;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 10px;
}

.tag {
    display: flex;
    align-items: center;
    padding: 0px 5px;
    width: 100%;
    margin-bottom: 5px;
}

.tag-text {
    min-width: 80%;
}

.remove-button {
    margin-left: 5px;
    background: none;
    border: none;
    cursor: pointer;
}