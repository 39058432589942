.mutex-lists {
    display: grid;
    grid-template-columns: 47% 5% 48%;
    margin-left: 10px;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
}

.divider {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}