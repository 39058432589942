:root {
    --font-family: 'Helvetica Neue', sans-serif;
    /* font for certificates */
    --cert-font-family: 'Helvetica Neue', sans-serif;
    --font-family-secondary: 'Rubik', 'Poppins', 'inter', 'Helvetica Neue', sans-serif;
    --font-family-primary: 'Lato', 'Helvetica Neue', sans-serif;

    --spacing-none: 0;
    --spacing-xxs: 2px;
    --spacing-xs: 5px;
    --spacing-sm: 6px;
    --spacing-md: 8px;
    --spacing-lg: 12px;
    --spacing-xl: 20px;
    --spacing-2xl: 24px;
    --spacing-3xl: 32px;
    --spacing-4xl: 40px;
    --spacing-5xl: 48px;
    --spacing-6xl: 64px;
    --spacing-7xl: 80px;
    --spacing-8xl: 96px;
    --radius-full: 9999px;
    --radius-sm: 5px;
    --radius-md: 8px;
    --radius-lg: 10px;
    --radius-xl: 12px;
    --logo-color: rgb(255, 107, 78);

    --text-primary: #f7f7f7;
    --drop-down-text: #333;
    --text-primary_on-brand: #cecfd2;
    --text-secondary: #ececed;
    --text-secondary_on-brand: #cecfd2;
    --text-secondary_hover: #cecfd2;
    --text-tertiary: #cecfd2;
    --text-tertiary_on-brand: #94969c;
    --text-tertiary_hover: #94969c;
    --text-quaternary: #94969c;
    --text-quaternary_on-brand: #94969c;
    --text-white: #ffffff;
    --text-disabled: #85888c;
    --text-placeholder: #85888c;
    --text-placeholder_subtle: #61646c;
    --text-brand-primary: #d7eae4;
    --text-brand-secondary: #A2c1ba;
    --text-brand-tertiary: #7eaca2;
    --text-brand-tertiary-alt: #f5f5f6;
    --text-error-primary: #f97066;
    --text-warning-primary: #fdb022;
    --text-success-primary: #00da45;

    --border-primary: #333741;
    --border-secondary: #1f242f;
    --border-tertiary: #161b26;
    --border-disabled: #333741;
    --border-disabled_subtle: #1f242f;
    --border-brand: #73aca2;
    --border-brand-solid: #c4dfd9;
    --border-brand_alt: #333741;
    --border-error: #f97066;
    --border-error-solid: #f04438;

    --fg-primary: #ffffff;
    --fg-secondary: #cecfd2;
    --fg-secondary_hover: #ececed;
    --fg-tertiary: #94969c;
    --fg-tertiary_hover: #cecfd2;
    --fg-quaternary: #94969c;
    --fg-quaternary_hover: #cecfd2;
    --fg-quinary: #85888e;
    --fg-quinary_hover: #94969c;
    --fg-senary: #61646c;
    --fg-white: #ffffff;
    --fg-disabled: #85888c;
    --fg-disabled_subtle: #61646c;
    --fg-brand-primary: #d7eae4;
    --fg-brand-primary_alt: #cecfd2;
    --fg-brand-secondary: #A2c1ba;
    --fg-error-primary: #f04438;
    --fg-error-secondary: #f97066;
    --fg-warning-primary: #f79009;
    --fg-warning-secondary: #fdb022;
    --fg-success-primary: #00a833;
    --fg-success-secondary: #00da45;

    --bg-primary: #0a0a0a;
    --bg-primary_alt: #1c1c1e;
    --bg-primary-hover: #1c1c1e;
    --bg-primary-solid: #1c1c1e;
    --bg-secondary: #1c1c1e;
    --bg-secondary_alt: #0a0a0a;
    --bg-secondary_hover: #252528;
    --bg-secondary_subtle: #161b26;
    --bg-secondary-solid: #61646c;
    --bg-tertiary: #252528;
    --bg-quaternary: #313136;
    --bg-active: #1f242f;
    --bg-disabled: #0a0a0a;
    --bg-disabled_subtle: #1c1c1e;
    --bg-overlay: #0c111d;
    --bg-brand-primary: #518778;
    --bg-brand-primary_alt: #1c1c1e;
    --bg-brand-secondary: #2a595a;
    --bg-brand-solid: #a2c1ba;
    --bg-brand-solid_hover: #002e3a;
    --bg-brand-section: #1c1c1e;
    --bg-brand-section_subtle: #0a0a0a;
    --bg-error-primary: #f04438;
    --bg-error-secondary: #d92d20;
    --bg-error-solid: #d92d20;
    --bg-warning-primary: #f79009;
    --bg-warning-secondary: #dc6803;
    --bg-warning-solid: #dc6803;
    --bg-success-primary: #00a833;
    --bg-success-secondary: #008a28;
    --bg-success-solid: #008a28;

    --bar-chart-top: #dbe8e5;
    --bar-chart-middle: var(--bg-brand-primary);
    --bar-chart-bottom: #002e38;
    --bar-chart-radius: 8;

    --Gradient-Dark: linear-gradient(15deg, #35555B 10.12%, #0A0A0A 51.24%);
}

body {
    background-color: var(--bg-secondary);
    color: var(--text-secondary);
    font-family: var(--font-family);
}

/* @media (prefers-color-scheme: light) {
    :root {
            --text-primary: #1F242F;
            --text-primary_on-brand: #C7CCD4;
            --text-secondary: #61646C;
            --text-secondary_hover: #333741;
            --text-secondary_on-brand: #B9EDFE;
            --text-tertiary: #85888E;
            --text-tertiary_on-brand: #B9EDFE;
            --text-tertiary_hover: #61646C;
            --text-quaternary: #94969C;
            --text-quaternary_on-brand: #79E0FD;
            --text-white: #ffffff;
            --text-disabled: #94969C;
            --text-placeholder: #3F3F48;
            --text-placeholder_subtle: #CECFD2;
            --text-brand-primary: #2A595A;
            --text-brand-secondary: #7EACA2;
            --text-brand-tertiary: #A2C1BA;
            --text-brand-tertiary-alt: #A2C1BA;
            --text-error-primary: #D92D20;
            --text-warning-primary: #DC6803;
            --text-success-primary: #008A28;

            --border-primary: #CECFD2;
            --border-secondary: #ECECED;
            --border-tertiary: #f0f1f1;
            --border-disabled: #C7CCD4;
            --border-disabled_subtle: #DBDDE0;
            --border-brand: #A2C1BA;
            --border-brand-solid: #D7EAE4;
            --border-brand_alt: #A2C1BA;
            --border-error: #FDA29B;
            --border-error-solid: #D92D20;

            --fg-primary: #161B26;
            --fg-secondary: #333741;
            --fg-secondary_hover: #161b26;
            --fg-tertiary: #61646C;
            --fg-tertiary_hover: #333741;
            --fg-quaternary: #85888E;
            --fg-quaternary_hover: #61646C;
            --fg-quinary: #CECFD2;
            --fg-quinary_hover: #94969c;
            --fg-senary: #ECECED;
            --fg-white: #ffffff;
            --fg-disabled: #94969C;
            --fg-disabled_subtle: #CECFD2;
            --fg-brand-primary: #518178;
            --fg-brand-primary_alt: #A2C1B1;
            --fg-brand-secondary: #A2C1BA;
            --fg-error-primary: #D92D10;
            --fg-error-secondary: #F04438;
            --fg-warning-primary: #DC6803;
            --fg-warning-secondary: #F79009;
            --fg-success-primary: #008A28;
            --fg-success-secondary: #00AB33;
        
            --bg-primary: #ffffff;
            --bg-primary_alt: #ffffff;
            --bg-primary-hover: #f7f9fa;
            --bg-primary-solid: #0c111d;
            --bg-secondary: #f7f9fa;
            --bg-secondary_hover: #e6e8eb;
            --bg-secondary_alt: #f7f9fa;
            --bg-secondary_subtle: #fcfcfd;
            --bg-secondary-solid: #313136;
            --bg-tertiary: #edf0f2;
            --bg-quaternary: #dbdde0;
            --bg-active: #f7f9fa;
            --bg-disabled: #edf0f2;
            --bg-disabled_subtle: #edf0f2;
            --bg-overlay: white;
            --bg-brand-primary: #c4dfd9;
            --bg-brand-primary_alt: #f0f9ff;
            --bg-brand-secondary: #d7eae4;
            --bg-brand-solid: #a2c1ba;
            --bg-brand-solid_hover: #a2c1ba;
            --bg-brand-section: #518178;
            --bg-brand-section_subtle: #7eaca2;
            --bg-error-primary: #fef3f2;
            --bg-error-secondary: #fee4e2;
            --bg-error-solid: #d92d20;
            --bg-warning-primary: #fffaeb;
            --bg-warning-secondary: #fef0c7;
            --bg-warning-solid: #dc6803;
            --bg-success-primary: #dfffd9;
            --bg-success-secondary: #c8ffc1;
            --bg-success-solid: #008a28;
        
            --bar-chart-top: #dbe8e5;
            --bar-chart-middle: var(--bg-brand-primary);
            --bar-chart-bottom: #002e38;
            --bar-chart-radius: 8;
        }
} */

.content {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    overflow: hidden;
    background: var(--Gradient-Dark);
}


main {
    max-height: 90vh;
    height: 90vh;
    padding-top: var(--spacing-xl);
    overflow-y: auto;
    margin-left: var(--spacing-xl);
    border-top: 1px solid var(--bg-brand-primary);
}


.icon-text-pair {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* Adjust space between pairs */
}

.icon-text-pair-left {
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: left;
    margin-bottom: 10px;
    /* Adjust space between pairs */
}

/* .icon-text-pair-td {
    display: grid;
    grid-template-rows: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
    justify-self: center;
} */

.icon-text-pair-td {
    display: grid;
    grid-template-rows: auto auto;
    align-items: center;
    justify-items: center;
    text-align: center;
    justify-self: center;
}

.icon-td {
    width: 20px;
    height: 20px;
    margin-bottom: 6px;
    /* Adjust space between the icon and text */
}

.icon {
    width: 20px;
    height: 20px;
    margin-right: 6px;
    /* Adjust space between the icon and text */
}

.icon-btn {
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    background-color: var(--bg-brand-secondary);
    border: none;
    border-radius: 5px;
    padding: 3px;
    color: white;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, transform 0.2s;
}

.icon-btn:hover {
    background-color: var(--bg-brand-solid_hover);
    transform: scale(1.05);
}

.small-logo {
    flex-shrink: 0;
    width: 152px;
    height: 25px;
}

.text {
    font-family: var(--font-family);
    font-size: medium;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="date"],
select,
textarea {
    font-family: var(--font-family);
    font-size: medium;
    width: 95%;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: var(--radius-sm);
    border: 1px solid var(--border-brand);
    background-color: transparent;
    color: var(--text-primary);
}

select option {
    color: var(--logo-color) !important;
}

select optgroup {
    color: var(--logo-color) !important;
    font-weight: bold;
}

input[type="checkbox"] {
    transform: scale(1.5);
}

input[type="text"]:read-only,
input[type="password"]:read-only,
input[type="email"]:read-only,
input[type="number"]:read-only,
input[type="date"]:read-only,
input[type="file"]:read-only,
textarea:read-only {
    border: none;
    cursor: default;
    font-size: large;
    color: var(--text-brand-primary);
}

input[type="radio"] {
    transform: scale(1.5);
}

select:disabled {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 10px solid var(--border-brand);
    cursor: default;
}

input:-webkit-autofill {
    background-color: transparent !important;
    color: var(--text-primary) !important;
    -webkit-text-fill-color: var(--text-primary) !important;
    box-shadow: 0 0 0px 1000px black inset !important;
}

input[type="file"] {
    font-family: var(--font-family);
    font-weight: bold;
    font-size: medium;
    width: 95%;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: var(--radius-sm);
    border: 1px solid var(--border-brand);
    background-color: transparent;
    color: var(--text-primary);
}

input[type="text"]:disabled,
input[type="password"]:disabled,
input[type="email"]:disabled,
input[type="number"]:disabled,
input[type="date"]:disabled,
select:disabled,
textarea:disabled {
    background-color: lightgray;
    color: var(--text-primary);
}

button {
    font-family: var(--font-family);
    font-weight: bold;
    font-size: medium;
    min-width: 100px;
    width: 100%;
    padding: 10px;
    background-color: var(--bg-brand-primary);
    color: #fff;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    display: inline-block;
    transition: background-color 0.3s;
}

.button-cta {
    display: inline-flex;
    padding: var(--spacing-lg, 12px) var(--spacing-2xl, 20px);
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: var(--radius-full, 9999px);
    background: var(--logo-color, orange);
    color: var(--text-white, white);
    width: fit-content;

    /* Shadows/shadow-md */
    box-shadow: 0px 4px 6px rgba(230, 86, 14, 0.9);
}

.button-cta:hover:not(:disabled) {
    background-color: rgba(230, 86, 14, 0.9);
}

button:hover:not(:disabled) {
    background-color: var(--bg-brand-solid_hover);
}

button:disabled {
    background-color: gray;
    color: #ccc;
    cursor: not-allowed;
}

.small_button {
    font-family: var(--font-family);
    font-weight: bold;
    font-size: medium !important;
    width: max-content;
    height: max-content;
    padding: 10px;
    background-color: var(--bg-brand-primary);
    color: #fff;
    border: 1px solid #000000;
    border-radius: 15px;
    cursor: pointer;
    display: inline-block;
    transition: background-color 0.3s;
}

.small_button:hover:not(:disabled) {
    background-color: var(--bg-brand-solid_hover);
}

.less_used_button {
    font-family: var(--font-family);
    font-weight: bold;
    font-size: medium;
    width: max-content;
    height: max-content;
    padding: 10px;
    background-color: var(--bg-brand-solid_hover);
    color: #fff;
    border: 1px solid #000000;
    border-radius: 15px;
    cursor: pointer;
    display: inline-block;
    transition: background-color 0.3s;
}

.less_used_button:hover:not(:disabled) {
    background-color: var(--bg-brand-primary);
}


h1 {
    font-family: var(--font-family);
    font-weight: bold;
    text-align: center;
    font-size: 40px;
    /* Adjust the font size as needed */
}

.red {
    border: 1px solid #f4cece;
    width: max-content;
    height: max-content;
    border: 1px solid #000000;
    background-color: var(--bg-brand-section);
}

.red:hover:not(:disabled) {
    background-color: rgb(244, 55, 55);
    color: var(--text-primary);
}

a {
    color: var(--text-brand-secondary);
}

a:hover {
    color: var(--text-brand-tertiary);
    text-decoration: underline;
}

a:active {
    color: green;
}

label {
    font-family: var(--font-family);
    font-weight: bold;
    display: inline-block;
    margin-bottom: 5px;
}

.three_buttons {
    font-family: var(--font-family);
    max-width: 80%;
    margin: auto auto auto auto;
    padding: 20px;
    border-radius: 5px;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-column-gap: 10px;
    grid-row-gap: 5px;
    align-items: center;
}

.four_buttons {
    font-family: var(--font-family);
    max-width: 80%;
    margin: auto auto auto auto;
    padding: 20px;
    border-radius: 5px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-column-gap: 10px;
    grid-row-gap: 5px;
    align-items: center;
}

.four_buttons button {
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
}

.flex_row {
    font-family: var(--font-family);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.flex_row_50_50 {
    display: flex;
    width: 100%;
    padding: 0%;
    margin: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 20px!important;
}

.flex_row_no_margin {
    font-family: var(--font-family);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin: 0;
    padding: 0;
}

.flex_col {
    font-family: var(--font-family);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 10px; */
}

.three_buttons button {
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
}

.eng_layout {
    font-family: var(--font-family);
    max-width: 100%;
    margin: auto auto auto auto;
    padding: 20px;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 18% 18% 18% 18%;
    grid-column-gap: 2px;
    grid-row-gap: 5px;
    align-items: left;
}

.eng_layout button {
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
}

.eng_layout .toggle_buttons {
    background-color: var(--bg-brand-solid-hover);
    color: var(--text-primary);
}

.eng_layout .toggle_buttons:hover {
    background-color: var(--bg-brand-primary);
    color: var(--text-primary);
}

.eng_layout .toggle_buttons:disabled {
    background-color: var(--bg-brand-primary);
    color: var(--text-primary);
}

.org_layout {
    font-family: var(--font-family);
    max-width: 100%;
    margin: auto auto auto auto;
    padding: 20px;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 20% 20% 20% 20%;
    grid-column-gap: 2px;
    grid-row-gap: 5px;
    align-items: left;
}

.org_layout button {
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
}

.org_layout .toggle_buttons {
    background-color: var(--bg-brand-solid-hover);
    color: var(--text-primary);
}

.org_layout .toggle_buttons:hover {
    background-color: var(--bg-brand-primary);
    color: var(--text-primary);
}

.org_layout .toggle_buttons:disabled {
    background-color: var(--bg-brand-primary);
    color: var(--text-primary);
}

.job_div {
    background-color: var(--back-ground-color);
    color: var(--text-primary);
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    cursor: pointer;
    display: grid;
    place-items: center;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.1s ease;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    border-top-left-radius: 15px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 0;
}

.job_div:hover {
    background-color: var(--bg-brand-solid_hover);
}

.job_div:active {
    background: var(--bg-brand-primary);
    color: white;
    border: 0.5px solid var(--bg-brand-primary);
}

.job_div.selected {
    background-color: var(--bg-brand-secondary);
    border: 0.5px solid var(--bg-brand-secondary);
}

.job_div h2 {
    margin: 0;
    padding: 0;
    align-self: left;
}

.container_with_steps {
    font-family: var(--font-family);
    max-width: 50%;
    width: 50%;
    max-height: fit-content;
    margin: auto auto;
    padding: 10px;
    border-radius: 5px;
    display: grid;
    grid-template-rows: 30% 70%;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.flex-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.flex-container>* {
    flex-grow: 1;
    flex-basis: 0;
    margin: 10px;
}


.two_columns {
    font-family: var(--font-family);
    max-width: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
}

.two_columns_30_70 {
    font-family: var(--font-family);
    max-width: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 30% 70%;
}

.three_columns {
    font-family: var(--font-family);
    max-width: 100%;
    width: 100%;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 33% 33% 33%;
}

.whoami {
    font-family: var(--font-family);
    max-width: min(100%, 900px);
    background-color: var(--back-ground-color);
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    align-items: top;
    justify-items: left;
    color: #fff;
}

.whoami-wide {
    font-family: var(--font-family);
    max-width: min(100%, 1200px);
    background-color: var(--back-ground-color);
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    align-items: top;
    justify-items: left;
    color: #fff;
}

.tos {
    margin-top: 10px;
    font-size: medium !important;
}

.flex-row {
    font-family: var(--font-family);
    max-width: 100%;
    background-color: var(--back-ground-color);
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-right: 5px;
    align-items: top;
    justify-items: left;
    color: #fff;
}

.flex-row-no-margin {
    font-family: var(--font-family);
    max-width: 100%;
    background-color: var(--back-ground-color);
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: top;
    margin: 0;
    padding: 0;
    justify-items: left;
    color: #fff;
}

.whoami label {
    padding-right: 5px;
    text-align: left;
    margin-left: 0px;
    font-size: small;
}

.two_buttons {
    font-family: var(--font-family);
    max-width: 100%;
    margin: auto auto;
    padding: 20px;
    border-radius: 5px;
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 10px;
    grid-row-gap: 5px;
    align-items: center;
    justify-content: center;
}

.two_buttons button {
    margin: 0;
    max-width: 100%;
    padding: 10px 10px;
    box-sizing: border-box;
}

.three_with_small_mid_section {
    display: grid;
    grid-template-columns: 40% 20% 40%;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-top: 20px;
}