.outer-box {
    margin: 2px 2px;
    max-width: 100%;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.right-pane {
    max-width: 80%;
    margin: auto 20px;
    width: 80%;
    box-sizing: border-box;
    overflow: auto;
}

.scroll_box {
    width: 100%;
    height: 300px;
    overflow-y: auto;
}

.right-pane h2 {
    grid-column: span 2;
    padding-bottom: 10px;
    margin-bottom: 2px;
    margin-top: 0;
    color: var(--text-brand-primary);
    text-align: left;
}

.menu_buttons {
    background-color: var(--back-ground-color);
    color: white;
    border: 1px solid var(--back-ground-color);
    margin: 0;
}

.menu_buttons:hover:not(:disabled) {
    background-color: black;
    color: white;
}