.copytext {
    display: grid;
    align-items: center;
    grid-template-columns: max-content 10%;
}


.copy-button {
    background: none;
    border: none;
    cursor: pointer;
    align-items: left;
    justify-content: left;
    text-align: left;
}

.copytext .copy-button:hover {
    background: none;
    color: var(--bg-brand-solid-hover);
}

.copytext .copy-button:disabled {
    background: none;
    color: white;
}