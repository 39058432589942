/* Spinner styles */
.spinner {
    height: 80px;
    width: 80px;
    border: 8px solid var(--logo-color);
    border-top: 8px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

/* Keyframes for spinning animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Centering container */
.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
    flex-direction: column;
}

.loading-text {
    margin-top: 10px;
    font-size: 1.2rem;
    color: white;
}