/* footer.css */

.footer {
  /* position: fixed; */
  width: 100%;
  height: 30px;
  max-height: 30px;
  bottom: 0;
  padding: 10px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.5);
  color: var(--text-secondary);
  display: flex;
  /* z-index: 999; */
}

.footer h1 {
  margin: 0;
  font-size: 18px;
}

.footer-content {
  display: grid;
  justify-self: start;
  grid-template-columns: 50% 10% 10% 10% 10% 10%;
  width: 100%;
  height: 30px;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  /* z-index: 1000; */
}