/* header.css */
  .header {
    display: flex;
    align-items: center;
    padding: var(--spacing-sm, 8px) var(--spacing-md, 8px);
    gap: var(--spacing-lg, 12px);

    background: var(--bg-primary, #0A0A0A);
  }
  
  .modelteam-icon {
    width: 33px;
    height: 33px;
    flex-shrink: 0;
  }

  .header-navi {
    padding: 0px var(--spacing-none, 0px);
    gap: var(--spacing-5xl, 40px);
    margin-left: auto;
  }
  
  .header-link {
    display: flex;
    gap: var(--spacing-5xl, 40px);

    color: var(--text-secondary, #ECECED);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  
  .header-link a {
    color: var(--text-secondary, #ECECED);
    text-decoration: none;
  }
  

.logo {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
}


/* .dropdown {
    position: relative;
    display: inline-block;
}

.dropbtn {
    background-color: #444444;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: #f1f1f3;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #f1f1f1;
}

.show {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: #555555;
}
   */