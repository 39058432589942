.progress-tracker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    margin: auto;
    position: relative;
}

.progress-tracker-left {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 600px;
    position: relative;
    justify-content: flex-start;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex: 1;
}

.step-icon {
    width: 32px;
    height: 32px;
    background: #ddd;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
}

.step img {
    width: 20px;
    height: 20px;
}

.step-line {
    position: absolute;
    width: 100%;
    height: 4px;
    background: #ddd;
    top: 16px;
    left: 50%;
    z-index: 1;
}

.step.completed .step-icon {
    background: white;
}

.step.completed .step-line {
    background: var(--bg-brand-primary);
}

.step.active .step-icon {
    background: #1E3A8A;
}

.step-label {
    margin-top: 8px;
    background: var(--bg-brand-section);
    border: 0px;
    font-size: 14px;
    cursor: pointer;
    color: var(--text-brand-secondary);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 0.5px solid var(--border-disabled);
}

.step-label.active {
    background: var(--bg-brand-primary)!important;
    border: 0.5px solid var(--bg-brand-primary);
    color: white;
}