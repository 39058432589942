.profile-tab {
    cursor: pointer;
    display: flex;
    padding: var(--spacing-md, 15px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-none, 0px);
    color: var(--text-brand-secondary);
    font-weight: bold;
    border: 0.5px solid var(--border-disabled);
    background: var(--bg-brand-section);

    padding: 10px 20px;
    font-size: 16px;
    border-radius: 8px 8px 0 0;
    transition: all 0.3s ease-in-out;
}

.profile-tab.active {
    background: var(--bg-success-solid)!important;
    color: white;
    border: 0.5px solid var(--bg-success-solid);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.profile-tab:hover {
    background: var(--bg-brand-secondary);
    color: white;
    border: 0.5px solid var(--bg-brand-secondary);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.tab-group {
    display: flex;
    gap: var(--spacing-md, 15px);
    justify-content: flex-start;
    width: 100%;
    border-bottom: 1px solid var(--bg-brand-primary);
}