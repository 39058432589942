.welcome-container {
    width: Fixed (1,512px)px;
    height: Fixed (982px)px;
    min-height: 982px;
    top: 200px;
    left: -2578px;

    display: flex;
    flex-wrap: wrap;
    justify-content: column;
    margin-top: 20px;
    max-width: 50%;
    flex-direction: flex-end;
    padding: var(--spacing-8xl) var(--spacing-5xl) var(--spacing-8xl) var(--spacing-5xl);
    gap: var(--spacing-5xl);
    opacity: 0px;
}

.welcome-grahic {
    width: Fill (981px)px;
    height: Fill (982px)px;
    padding: var(--spacing-8xl) var(--spacing-5xl) var(--spacing-8xl) var(--spacing-5xl);
    gap: var(--spacing-5xl);
    opacity: 0px;    
}

.welcome-content {
    width: Fixed (531px)px;
    height: Fill (982px)px;
    padding: var(--spacing-8xl) var(--spacing-6xl) var(--spacing-8xl) var(--spacing-6xl);
    gap: var(--spacing-3xl);
    opacity: 0px;
}

.welcome-big-text {
    color: var(--text-primary, #F7F7F7);

    /* Display lg/Semibold */
    font-family: var(--font-family-primary, 'Lato');
    font-size: 72px;
    font-style: semibold;
    font-weight: 700;
    line-height: 90px;
    letter-spacing: -0.02em;
    text-align: left;
    white-space: normal;
    word-break: break-word;
    overflow-wrap: break-word;
    max-width: 100%;
    color: var(--text-primary, #F7F7F7);
    
}

.welcome-subtitle {
    color: var(--text-secondary, #ECECED);
    font-family: var(--font-family-secondary, 'Rubik');
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;

}