.cert-container {
    --bg-primary: #ffffff;
    --bg-primary_alt: #ffffff;
    --bg-primary-hover: #f7f9fa;
    --bg-primary-solid: #0c111d;
    --bg-secondary: #f7f9fa;
    --bg-secondary_hover: #e6e8eb;
    --bg-secondary_alt: #f7f9fa;

    --text-primary: #1F242F;
    --text-primary_on-brand: #C7CCD4;
    --text-secondary: #61646C;
    --text-secondary_hover: #333741;
    --text-secondary_on-brand: #B9EDFE;
    --text-tertiary: #85888E;
    --text-tertiary_on-brand: #B9EDFE;
    --text-tertiary_hover: #61646C;
    --text-quaternary: #94969C;
}
    

@media print {
    .cert-container {
        width: 8.5in;
        height: 11in;
        margin: 0;
        padding: 1in;
        background-color: var(--bg-primary);
        box-sizing: border-box;
    }
}

.cert-container {
    font-family: var(--cert-font-family);
    width: 816px;
    height: 1056px;
    min-width: 816px;
    min-height: 1056px;
    max-width: 816px;
    max-height: 1056px;
    overflow: hidden;
    background-color: var(--bg-primary);
    color: var(--text-secondary);
    font: var(--font-family);
    padding: var(--spacing-xl, 20px) var(--spacing-3xl, 32px);
    box-sizing: border-box;
    position: relative;
    margin-bottom: 80px;
}

.profile-charts-cert {
    max-height: 100%;
    top: 15px;
    left: 250px;
}

.summary {
    max-width: 100%;
    width: 100%;
    align-items: left;
    text-align: left;
}

.summary-header {
    font-family: var(--cert-font-family);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0;
    margin-bottom: 20px;
}

.summary-name {
    font-family: var(--cert-font-family);
    font-size: 1em;
    margin: 0;
    font-weight: bold;
    color: var(--text-secondary);
}

.summary-tops {
    font-family: var(--cert-font-family);
    display: flex;
    justify-content: flex-start;
    gap: 20px;
}

.summary-tops em {
    font-family: var(--cert-font-family);
    font-size: 1.2em;
    font-weight: bold;
    color: var(--text-secondary);
}

.summary-stats-container {
    font-family: var(--cert-font-family);
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 20px;
    margin-bottom: var(--spacing-md, 12px);
}

.cert-container .profile-skill-item {
    width: auto;
    height: 260px;
    padding: 5px;
    color: var(--text-secondary);
    background-color: var(--bg-primary);
    border: 0.5px solid var(--border-tertiary);
    margin-bottom: 0px;
}

.cert-container .profile-skills {
    font-family: var(--cert-font-family);
    width: auto;
    height: auto;
    padding: 5px;
    color: var(--text-secondary);
}

.cert-container .skill-item-caption-badge-text {
    color: var(--text-secondary);
}

.cert-container .skill-item-caption-stats-text {
    color: var(--text-secondary);
}

.cert-container .skill-item-caption-bigtext {
    color: var(--text-secondary);
}

.cert-container .skill-item-caption-smalltext {
    color: var(--text-secondary);
}

.cert-container em {
    color: var(--text-secondary);
}


.legend_container {
    font-family: var(--font-family);
    max-width: 100%;
    width: 100%;
    display: block;
}

.legend {
    font-family: var(--cert-font-family);
    display: flex;
    color: var(--text-secondary);
    text-align: center;
    justify-content: center;
}

.rt90 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    writing-mode: vertical-rl;
    text-align: center;
    transform: rotate(180deg);
    font-size: xx-large;
}

.pair-td {
    display: grid;
    grid-template-rows: auto auto;
    align-items: center;
    justify-items: center;
    text-align: left;
    justify-self: center;
    padding: 20px;
    gap: 10px;
    margin: 10px 10px;
}

.cert-box {
    font-family: var(--cert-font-family);
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: var(--spacing-lg, 12px);
}

/*.cert-container .skill-item-caption {
    font-family: var(--cert-font-family);
    max-width: 250px;
    font-size: small;
    color: var(--text-secondary);
}*/

/*.cert-container .lang-item-caption {
    font-family: var(--cert-font-family);
    max-width: 250px;
    font-size: small;
    grid-template-columns: 32% repeat(4, 17%);
    color: var(--text-secondary);
}
*/

.cert-container .skill-item-linechart {
    font-family: var(--cert-font-family);
    max-width: max(90%, 350px);
}

.cert-container .text {
    font-family: var(--cert-font-family);
}

.cert-container h4 {
    font-family: var(--cert-font-family);
    font-size: 1.2em;
    white-space: wrap;
    text-align: left;
}

.cert-logo-background {
    padding-bottom: var(--spacing-lg, 12px);
}

.cert-logo {
    height: 40px;
    padding: var(--spacing-none, 0px) var(--spacing-md, 10px);
}

.outer-box-cert {
    margin: 2px 2px;
    max-width: 100%;
    display: grid;
    grid-template-columns: 20% 80%;
    vertical-align: top;
}

.left-pane-cert {
    background-color: var(--bg-primary);
    width: 100%;
    max-width: 100%;
    overflow: auto;
    vertical-align: top;
}

.right-pane-cert {
    max-width: 100%;
    width: 100%;
    max-height: 75%;
    vertical-align: center;
    box-sizing: border-box;
    overflow: auto;
    margin-left: 10px;
    margin-bottom: 30px;
}

.cert-header {
    justify-content: space-between;
    align-items: center;
}

.cert-header h2 {
    margin: 0;
    margin-left: 10px;
    padding: 0;
}