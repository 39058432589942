.copilot-header {
    display: grid;
    grid-template-columns: 30% 30%;
    gap: var(--spacing-xl, 20px);
    max-width: 90%;
    font-family: 'Lato', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.96px;
    text-align: center;
    align-items: last baseline;
}

.copilot-content {
    display: flex;
    flex-direction: column;
    max-width: 1500px;
    margin-top: 20px;
}

.copilot {
    margin-top: 10px;
    margin-bottom:0px;
}

.saved_search_names {
    font-family: var(--font-family);
    border-radius: 5px;
    padding: 0px;
    display: flex;
    max-width: 1200px;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}