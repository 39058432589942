.profile-header {
    display: flex;
    padding-right: var(--spacing-3xl, 24px);
    text-align: left;

    font-family: 'Lato', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.96px;
    text-align: left;
    align-items: center;
}

.profile-body {
    top: 15px;
    left: 250px;
    gap: 0px;
}

.profile-body.shifted {
    margin-left: 250px;
}

.profile-body-header-container {
    padding: var(--spacing-xl) 9px 0px 9px;
    gap: 0px;
    justify-content: space-between;
    margin-bottom: 5px;
}

.user-text {
    font-family: var(--font-family);
    font-size: medium;
    text-align: left;
}

.profile-body-header-box {
    display: grid;
    grid-template-rows: auto;
    text-align: left;
    align-items: left;
    width: 100%;
}

.profile-body-header-box p {
    width: Fill (634px)px;
    height: Hug (24px)px;
    gap: var(--container-padding-mobile);
    opacity: 1;

    font-family: var(--font-family);
    font-size: small;
    font-weight: 600;
    text-align: center;
}

.profile-tabs-container {
    gap: var(--spacing-md);
    border-radius: var(--spacing-md);
    opacity: 1;
    overflow-x: auto;
    overflow-y: hidden;
    max-height: 80vh;
    margin-top: 10px;
}

.profile-tabs-bar {
    display: flex;
    flex-direction: row;
    max-height: 100%;

    font-family: 'Rubik', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border-bottom: 1px solid var(--bg-brand-primary);
    margin-bottom: var(--spacing-md);
}

.profile-tabs {
    display: flex;
    /* padding: var(--spacing-md, 8px) var(--spacing-lg, 12px); */
    justify-content: flex-end;
    align-items: center;
    gap: var(--spacing-lg, 12px);
    align-self: stretch;
}

.profile-tabs-left {
    display: flex;
    padding: var(--spacing-md, 8px) var(--spacing-lg, 12px);
    justify-content: flex-start;
    align-items: center;
    gap: var(--spacing-lg, 12px);
    align-self: stretch;
}

.profile-tabs-4-tabs {
    display: grid;
    grid-template-columns: 10% 10% 10% 10% 60%;
    justify-content: right;
    max-height: 100%;
}

.profile-tab-content {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: var(--radius-full);
    max-width: 400px;
    margin: 0 auto;
    font-family: 'Rubik', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin: 0 auto;
}

.profile-account-info {
    display: flex;
    padding: var(--spacing-none, 0px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-md, 12px);
    flex: 1 0 0;
    max-width: 90%;
}

.profile-account-edit {
    width: 70px;
    max-width: 100px;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: var(--spacing-md, 12px);
}

.profile-account-name {
    color: var(--text-primary, #F7F7F7);

    /* Display lg/Semibold */
    font-family: 'Lato', 'Helvetica Neue', sans-serif;
    font-size: clamp(24px, 5vw, 48px);
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    /* 125% */
    letter-spacing: -0.96px;
    text-align: left;
    white-space: normal;
    word-break: break-word;
    overflow-wrap: break-word;
    max-width: 100%;
}

.profile-account-text {
    color: var(--text-primary, #F7F7F7);
    text-align: right;

    /* Text lg/Regular */
    font-family: 'Rubik', 'Poppins', 'inter', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 155.556% */
}

.profile-account-about-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.profile-account-about-title {
    width: 421.333px;
    height: 24px;

    overflow: hidden;
    color: var(--text-primary, #F7F7F7);
    text-overflow: ellipsis;
    white-space: nowrap;

    font-size: 20px;
    font-style: bold;
    font-weight: 500;
    line-height: 24px;
}

.profile-account-about-text {
    align-self: stretch;
    color: var(--text-primary, #F7F7F7);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.verification-container {
    display: flex;
    padding: var(--spacing-none, 0px);
    align-items: flex-start;
    gap: var(--spacing-3xl, 24px);
    align-self: stretch;
    max-width: 80%;
}

.profile-skills {
    width: max(100%, 420px);
    height: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(420px, 32%));
    gap: var(--spacing-lg, 12px) var(--spacing-lg, 12px);
    z-index: 999;
}

/* the dimensions of profile-account-container and profile-skill-item should be same */
.profile-account-container {
    width: max(90%, 420px);
    height: 345px;
    max-height: 345px;
    display: flex;
    flex-direction: column;
    padding: var(--spacing-lg, 12px);
    align-items: flex-start;
    gap: var(--spacing-md, 12px);
    align-self: stretch;
}

.profile-skill-item {
    width: max(90%, 420px);
    height: 345px;
    max-height: 345px;
    padding: var(--spacing-xl) var(--spacing-xl) var(--spacing-xl) var(--spacing-xl);
    border-radius: var(--radius-xl);
    opacity: 1;
    background-color: var(--bg-primary);
    margin-bottom: var(--spacing-md);
    overflow-x: auto;
}

.profile-skill-item-baseline {
    width: max(90%, 420px);
    aspect-ratio: 16 / 11;
    min-height: 400px;
    padding: var(--spacing-xl) var(--spacing-xl) var(--spacing-xl) var(--spacing-xl);
    border-radius: var(--radius-xl);
    opacity: 1;
    background-color: var(--bg-primary);
    margin-bottom: var(--spacing-md);
    overflow-x: auto;
}

.profile-name-card {
    display: flex;
    width: auto;
    /* padding: var(--spacing-lg, 12px) var(--spacing-3xl, 24px);*/
    flex-direction: row;
    align-items: flex-start;
    gap: var(--spacing-xl, 20px);
    max-width: 100%;
}

.profile-skill-item-container {
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-none, 0px);
    align-self: stretch;

    width: 100%;
    height: 100%;
    gap: 0px;
    opacity: 1;
    display: grid;
    grid-template-rows: 30% 70%;
}

.profile-skill-item-container-baseline {
    width: 100%;
    height: 100%;
    gap: 0px;
    opacity: 1;
    display: grid;
    grid-template-rows: 35% 65%;
}

.skill-item-caption {
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-none, 0px);
    align-self: stretch;
}

.skill-item-caption-bigtext {
    color: var(--text-primary, #F7F7F7);

    /* Display lg/Semibold */
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    /* 125% */
    /* letter-spacing: -0.96px; */
}

.skill-item-caption-smalltext {
    color: var(--text-primary, #F7F7F7);

    /* Lowercase letter styling */
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
    /* letter-spacing: -0.48px; */
}

.skill-item-caption-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    flex: 1 0 auto;
    padding: var(--spacing-none, 0px);
    font-family: 'Lato', 'Helvetica Neue', sans-serif;
}

.skill-item-caption-fullname {
    font-size: 1em;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-bottom: var(--spacing-md);
    vertical-align: center;
    font-family: 'Rubik', 'Helvetica Neue', sans-serif;
}

.skill-item-caption-stats {
    display: flex;
    padding: var(--spacing-none, 0px);
    align-items: flex-start;
    gap: var(--spacing-xs, 4px);
}

.skill-item-caption-stats-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--text-primary, white);

    /* Text sm/Light */
    font-family: 'Rubik', 'Poppins', 'inter', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
}

.skill-item-caption-stats-text em {
    /* Text sm/Regular */
    color: var(--text-primary, #ECECED);
    font-family: 'Rubik', 'Poppins', 'inter', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}

.skill-item-badge-container {
    display: flex;
    flex-direction: column;
    padding-top: var(--spacing-sm, 6px);
    justify-content: flex-start;
    align-items: center;
    gap: var(--spacing-lg, 12px);
}

.skill-item-caption-badge {
    display: flex;
    padding-top: var(--spacing-sm, 6px);
    justify-content: flex-end;
    align-items: center;
    gap: var(--spacing-none, 0px);
    flex: 1 0 auto;
    /* Text lg/Regular */
    font-family: 'Rubik', 'Poppins', 'inter', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    /* 155.556% */
}

.skill-item-caption-badge-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: start;
    color: var(--text-primary, #F7F7F7);
}

.skill-item-caption-badge-label {
    /* Text sm/Regular */
    display: flex;
    align-items: flex-start;
    font-size: 12px;
    color: var(--text-quarterary, #94969c);
}

.skill-item-caption-badge-icon {
    display: flex;
    padding-top: var(--spacing-sm, 6px);
    justify-content: flex-end;
    align-items: center;
    gap: var(--spacing-none, 0px);
}

.lang-item-caption {
    width: 80%;
    height: auto;
    display: grid;
    grid-template-columns: 35% repeat(4, 15%);
    grid-column-gap: 5px;
}

.skill-item-linechart {
    flex: 1;
    height: 100%;
    width: max(90%, 420px);
    opacity: 1;
    display: flex;
}

.skill-item-chart {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: var(--spacing-sm, 8px);
    align-self: stretch;
    height: auto;
    width: auto;
}

.skill-bar-chart {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0px var(--spacing-none, 0px);
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
    border: 1px solid #ccc;
    border-radius: var(--radius-md, 8px) var(--radius-md, 8px) 0px 0px;
    background: var(--Gradient-Vivid, linear-gradient(180deg, #D8EBE5 0%, #518178 47%, #002E3A 96%));
}

.skill-item-caption-baseline {
    width: 100%;
    height: auto;
    overflow-x: auto;
    overflow-y: hidden;
    gap: var(--spacing-lg);
    opacity: 1;
}

.skill-item-caption h4 {
    font-size: 1.2em;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-bottom: var(--spacing-md);
    vertical-align: center;
}

.lang-item-caption h4 {
    font-size: 1.2em;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-bottom: var(--spacing-md);
    vertical-align: center;
}

.job-container {
    width: Fill (1, 432px)px;
    height: Hug (86px)px;
    padding: var(--spacing-xl) var(--spacing-5xl) var(--spacing-xl) var(--spacing-5xl);
    border: 0px 0px 10px 0px;
    border-color: #161B26;
    opacity: 1;
    display: flex;
    justify-content: flex-start;
}

.job-title-container {
    width: 250px;
    height: auto;
    gap: var(--spacing-lg);
    padding: var(--spacing-sm) 0px var(--spacing-sm) 0px;
}

.job-title-container h4 {
    font-size: 1.2em;
    margin: 0;
    padding: 0;
    margin-bottom: var(--spacing-sm);
}

.job-detail-container {
    flex: 1;
    margin: 0;
    padding: var(--spacing-sm) 0px var(--spacing-sm) 0px;
    display: flex;
}

.job-dates {
    flex: 1;
    margin: 0;
    padding: 0;
    margin-bottom: var(--spacing-sm);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: right;
}

.dropdown-container {
    display: flex;
    justify-content: flex-end;
    /* padding: var(--spacing-md, 8px) var(--spacing-lg, 12px); */
    align-items: center;
    text-align: center;
    gap: var(--spacing-md);

    color: var(--text-quarterary, #94969C);
}

.custom-select {
    /* Remove default appearance */
    appearance: none;
    -webkit-appearance: none;
    /* For Safari */
    -moz-appearance: none;
    /* For Firefox */

    /* Basic styling */
    padding: 2px 24px 2px 8px;
    /* Add right padding for the caret */

    /* Position the caret */
    background-image: url('data:image/svg+xml;utf8,<svg fill="%23666" height="10" viewBox="0 0 10 6" width="10" xmlns="http://www.w3.org/2000/svg"><path d="M0 0l5 6 5-6z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 8px;
}

.custom-select:focus {
    border-color: #66afe9;
    outline: none;
}


select {
    width: 100%;
    text-align: center;
}

.user-table-container {
    border: 1px solid gainsboro;
    display: inline-block;
    width: 100%;
}

.user-table {
    border-collapse: collapse;
    width: 100%;
}

.user-table td {
    padding: 10px;
    /* max-width: 50%; */
    border-right: 1px solid gainsboro;
}

.user-table tr td:last-child {
    border-right: none;
}

.user-table tr {
    border: none;
}

.small_table_container {
    max-height: 80px;
    overflow-y: auto;
}

.small_table {
    font: var(--font-family);
    font-size: small;
    text-align: center;
}

.small_table th:first-child,
.small_table td:first-child {
    border-left: none;
    width: 20%;
    /* no wrap. add ellipsis */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.small_table th:not(:first-child),
.small_table td:not(:first-child) {
    width: 10%;
}

.table-with-border {
    font: var(--font-family);
    width: 100%;
    border: white 1px solid;
    margin: 10px 10px;
}

.table-with-border thead {
    background-color: #333;
}

.table-with-border th,
.table-with-border td {
    padding: 8px;
    width: 300px;
    max-width: 20%;
    text-align: center;
    overflow-wrap: break-word;
}


.table-with-border tbody tr:nth-child(even) {
    background-color: #444;
}

.table-with-border tbody tr:nth-child(odd) {
    background-color: #222;
}

.profile-charts {
    max-height: 75vh;
    top: 15px;
    left: 250px;
    gap: 0;
    overflow-y: auto;
}

.team-prof .profile-charts {
    max-height: 60vh;
}

.profile-charts-team-prof {
    max-height: 70vh;
    top: 15px;
    left: 250px;
    gap: 0;
    overflow-y: auto;
    /* opacity: 0; */
}

.right-pane-team-prof {
    flex: 2;
    max-width: 80%;
    vertical-align: center;
    box-sizing: border-box;
    overflow: auto;
    margin-left: 10px;
    margin-bottom: 200px;
}

.name-card {
    display: flex;
    width: 469.333px;
    padding: var(--spacing-lg, 12px) var(--spacing-3xl, 24px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-4xl, 32px);

    border-radius: var(--radius-sm, 6px);
    background-color: var(--bg-primary, #161B26);
}

.team-prof-header {
    display: grid;
    grid-template-columns: 25% 50% 20%;
    gap: var(--spacing-xl, 20px);
    max-width: 90%;
    text-align: left;
    font-family: 'Lato', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.96px;
    text-align: left;
    align-items: last baseline;
}

.skill-search-header {
    display: grid;
    grid-template-columns: 20% 30% 45% 5%;
    gap: var(--spacing-xl, 20px);
    max-width: 90%;
    text-align: left;
    font-family: 'Lato', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.96px;
    text-align: left;
    align-items: last baseline;
}

.skill-search-results-header {
    display: grid;
    grid-template-columns: 20% 20% 10% 10% 10%;
    gap: var(--spacing-xl, 20px);
    max-width: 90%;
    text-align: left;
    font-family: 'Lato', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.96px;
    text-align: left;
    align-items: last baseline;
}

.skill-search-results {
    display: flex;
    gap: var(--spacing-xl, 20px);
    max-width: 80%;
    text-align: left;
    font-family: 'Lato', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.96px;
    align-items: center;
}