.checkbox-group {
    justify-content: left;
    text-align: left;
    max-height: 300px;
    overflow-y: auto;
    width: max(100%, 300px);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 22%));
}

.checkbox-group-container h3 {
    margin-bottom: 4px;
    margin-top: 0;
    font-size: x-large;
    text-align: left;
}